/* Tracks print and copy events for Google Analytics.
*
* When a page is printed the 'afterprint' event listener is triggered
* and pushes a 'Print Intent' event to dataLayer.
*
* When something is copied on a page the 'copy' event listener is triggered
* and pushes a 'Copy Intent' event to dataLayer.
*/

if (typeof dataLayer !== 'undefined') {
    window.addEventListener("afterprint", function () {
        dataLayer.push({'event': 'Print Intent'});
    });

    window.addEventListener("copy", function () {
        dataLayer.push({'event': 'Copy Intent'});
    });

    window.addEventListener("dictionary event", function () {
        dataLayer.push({'event': 'Den Danske Ordbog'});
    });
}
